<script>
import appConfig from "@/app.config";

import Swal from "sweetalert2";
import axios from "axios";
/**
 * Dashboard Component
 */
export default {
    props: ["idDataEdit"],
    page: {
        title: "Edit Komite",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
    },
    data() {
        return {
            title: "Edit Komite",
            items: [
                {
                    text: "Master",
                    href: "/",
                },
                {
                    text: "Komite",
                    href: "/master/Komite",
                },
                {
                    text: "Edit Komite",
                    active: true,
                },
            ],
            // Catch Error Axios
            axiosCatchError: null,
            // variable Page
            komite_id: this.idDataEdit,
            nama: "",
            role_id: "",
            master_role: [],
            role_selected: [],
        };
    },
    mounted() {
        this.getRole();
        this.getKomite();
    },
    methods: {
        getRole() {
            let self = this;
            self.loadingTable = true;
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "/api/master/role")
                .then((response) => {
                    var response_data = response.data;
                    if (response_data.code == 200) {
                        self.master_role = response_data.list_data.role;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data.message,
                        });
                    }
                });
        },
        getKomite() {
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
                allowOutsideClick: false,
            });

            let self = this;
            axios
                .get(
                    process.env.VUE_APP_BACKEND_URL_VERSION +
                    "/api/master/komite?id=" +
                    self.komite_id
                )
                .then((response) => {
                    var response_data = response.data;
                    var response_data_fix = response_data.list_data.data[0];
                    if (response_data.code == 200) {
                        self.role_id = response_data_fix.mk_role_id;
                        self.nama = response_data_fix.mk_nama;

                        axios
                            .get(
                                process.env.VUE_APP_BACKEND_URL_VERSION +
                                "/api/master/role?&id=" +
                                self.role_id
                            )
                            .then((response) => {
                                var response_data = response.data;
                                var response_data_fix = response_data.list_data.role[0];
                                if (response_data.code == 200) {
                                    self.role_selected = response_data_fix;
                                } else {
                                    Swal.fire({
                                        icon: "error",
                                        title: "Oops...",
                                        text: response_data.message,
                                    });
                                }
                            });
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data.message,
                        });
                    }
                    Swal.close();
                });
        },

        StoreData() {
            let self = this;
            var role_id = self.role_selected?.id;
            if (role_id) {
                role_id = self.role_selected?.id;
            } else {
                role_id = '';
            }
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            var FormData = require("form-data");
            var data = new FormData();
            data.append("id", self.komite_id);
            data.append("role_id", role_id);
            data.append("nama", self.nama);

            var config = {
                method: "post",
                url:
                    process.env.VUE_APP_BACKEND_URL_VERSION +
                    "/api/master/komite/update",
                data: data,
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    if (response_data.code != 200) {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            html: response_data.message,
                        });
                    } else {
                        let timerInterval;
                        Swal.fire({
                            icon: "success",
                            title: "Berhasil",
                            text: "Anda akan diarahkan ke halaman master komite segera",
                            timer: 2000,
                            timerProgressBar: true,
                            showCancelButton: false,
                            showConfirmButton: false,
                            willClose: () => {
                                clearInterval(timerInterval);
                            },
                        }).then((result) => {
                            /* Read more about handling dismissals below */
                            if (result.dismiss === Swal.DismissReason.timer) {
                                self.$emit("refresh-table", "edit");
                            }
                        });
                    }
                })
                .catch((e) => {
                    this.axiosCatchError = e.response.data.data;
                    Swal.close();
                });
        },
    },
};
</script>

<template>
    <div class="row">
        <div class="col-lg-12">
            <b-form class="p-2" @submit.prevent="StoreData">
                <div class="row">
                    <div class="col-md-12">
                        <b-form-group class="mb-3" label="Nama Komite" label-for="formrow-nama-Komite-input">
                            <b-form-input id="formrow-nama-Komite-input" type="text" v-model="nama"></b-form-input>
                        </b-form-group>
                        <b-form-group class="mb-3" label="Role Komite">
                            <v-select placeholder="-Pilih Role-" :options="master_role" label="role"
                                v-model="role_selected"></v-select>
                        </b-form-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 text-end">
                        <b-button type="submit" variant="primary" class="m-1"><i class="fa fa-save"></i> Simpan</b-button>
                    </div>
                </div>
            </b-form>
        </div>
    </div>
</template>
